import { defineStore } from "pinia";

import type { TableRow } from "@/types/table";
import type { RequestError, requestParams } from "@/types/requests";

import { getPerformanceSummary as getPerformanceSummaryApi } from "@/api/adsSummary";
import type { PerformanceSummaryStore } from "@/types/adsSummary/performanceSummary";
import {
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const usePerformanceSummaryStore = defineStore("performanceSummary", {
  state: (): PerformanceSummaryStore => ({
    performanceSummaryData: [],
    performanceSummaryPagination: {
      totalPages: 1,
      currentPage: 1,
      pageSizeNumber: 10,
    },
    isPerformanceSummaryLoader: true,
    isPerformanceSummaryTotalValuesLoader: true,
    isByProductFilter: false,
  }),

  actions: {
    abortRequestsPerformanceSummaryStore() {
      this.cleanStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    changeByProductFilter(value: boolean) {
      this.isByProductFilter = value;
    },

    getPerformanceSummaryTableData(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isPerformanceSummaryTotalValuesLoader = true;
      } else {
        this.isPerformanceSummaryLoader = true;
      }
      getPerformanceSummaryApi(params, {
        signal: tableController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.performanceSummaryData = data.data;
            this.performanceSummaryPagination = data.pagination;
          }
          this.isPerformanceSummaryLoader = false;
          this.isPerformanceSummaryTotalValuesLoader = false;
        })
        .catch((err: RequestError) => {
          const errorData = JSON.parse(err?.message);
          this.isPerformanceSummaryLoader = false;
          this.isPerformanceSummaryTotalValuesLoader = false;
          if (errorData.status === "canceled") {
            this.isPerformanceSummaryLoader = true;
          }
        });
    },

    cleanStore() {
      this.performanceSummaryData = [];
      this.performanceSummaryPagination = {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      };
      this.isPerformanceSummaryLoader = true;
      this.isPerformanceSummaryTotalValuesLoader = true;
    },
  },
});
